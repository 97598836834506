<script lang="ts" setup>
import { onMounted, ref, defineProps } from "vue";
import ShoppingCart from "../../components/shoppingCart/shoppingCart.vue";
import {
  CheckoutStages,
} from "../../services/models/checkout.models";
import {
  PlaceholderAddressModel,
  Cart,
} from "../../utils/API/Cart/cartAPISchema";
import { getCart } from "../../utils/API/Cart/cartAPI";
import Checkout from "../../components/checkout/checkout.vue";
import Verification from "../../components/verification/verification.vue";
import { useCheckoutStateStore } from "../../components/checkout/stores/checkoutStateStore";
import { useShoppingCartStore } from "../../components/shoppingCart/stores/shoppingCartStore";
import { upsell } from "../../utils/API/Esales/EsalesAPI";
import { useUpsellStore } from "../../components/checkout/stores/upsellStore";
import { shouldGetUpsellModel } from "../../../../helpers/checkoutHelpers";
import { useFavoritesStore } from "../../stores/favoritesStore";
import { apiGetShoppingCart } from "../../services/checkout.service";

const props = defineProps<{
  ksps: string | null; // JSON Content from NewCheckoutIndex.cshtml
  oldCheckoutUrl: string | null;
  maxCartSize?: string;
  initialLineItemCount?: string; // Number of line items in the cart when the component is mounted
}>();

const checkoutStateStore = useCheckoutStateStore();
const shoppingCartStore = useShoppingCartStore();
const upsellStore = useUpsellStore();
const favoritesStore = useFavoritesStore();
const cart = ref<Cart>();
const customerInformation = ref<PlaceholderAddressModel>();


/**
 * Initializes the shopping cart by setting the loading states for the checkout and upsell stores,
 * then fetching the shopping cart and upsell model data from the API.
 * 
 * @async
 * @function initShoppingCart
 * @returns {Promise<void>} A promise that resolves when the shopping cart and upsell model data have been fetched and the loading states have been updated.
 */
const initShoppingCart = async () => {
  checkoutStateStore.setIsLoading(true);
  upsellStore.setIsLoading(true);

  const newCart = await apiGetShoppingCart();
  if (newCart) {
    shoppingCartStore.setCart(newCart);
  }
  checkoutStateStore.setIsLoading(false);

  await apiGetUpsellModel();
  upsellStore.setIsLoading(false);

  if (shoppingCartStore.cart?.isAuthenticated && !favoritesStore.favorites) {
    favoritesStore.getFavorites();
  }
};

/**
 * Fetches the upsell model based on the current shopping cart state.
 * 
 * This function checks if the shopping cart is available and if it meets the criteria
 * for fetching the upsell model. If both conditions are met, it makes an API call to 
 * retrieve the upsell model and updates the upsell store with the result.
 * 
 * @async
 * @function apiGetUpsellModel
 * @returns {Promise<void>} - A promise that resolves when the upsell model is fetched and stored.
 */
const apiGetUpsellModel = async () => {
  if (!shoppingCartStore.cart || !shouldGetUpsellModel(shoppingCartStore.cart)) return;

    const result = await upsell({
      Touchpoint: "DESKTOP",
      ProductKeys: shoppingCartStore.cart?.lineItems.map((lineItem) => lineItem.esalesKey),
      Preview: false,
    });

    if (result) {
      upsellStore.setUpsellModel(result);
    }
}

onMounted(() => {
  initShoppingCart();
});
</script>

<template>
  <div>
    <ShoppingCart
      v-if="
        checkoutStateStore.currentCheckoutStage === CheckoutStages.ShoppingCart
      "
      :ksps="props.ksps"
      :old-checkout-url="props.oldCheckoutUrl"
      :max-cart-size="props.maxCartSize"
      :initial-line-item-count="props.initialLineItemCount"
    />
    <Verification
      v-if="
        checkoutStateStore.currentCheckoutStage === CheckoutStages.Verification
      "
    ></Verification>
    <Checkout
      v-if="checkoutStateStore.currentCheckoutStage === CheckoutStages.Checkout"
      :customer-information="customerInformation"
      :cart="cart"
    />
  </div>
</template>
